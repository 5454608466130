type Environment = "development" | "production";

type APIBasePaths = {
  users: string;
  auth: string;
  storage: string;
};

type APIConfig = {
  [key in Environment]: APIBasePaths;
};

const API_CONFIG: APIConfig = {
  development: {
    users: "https://users.ZapTrack.cloud/v1/users",
    auth: "https://auth.ZapTrack.cloud/v1/auth",
    storage: "http://localhost:3203/v1/",
  },
  production: {
    users: "https://users.ZapTrack.cloud/v1/users",
    auth: "https://auth.ZapTrack.cloud/v1/auth",
    storage: "https://storage.zaptrack.cloud/v1/",

  },
};

const getCurrentEnvironment = (): Environment => {
  return (process.env.EXPORT as Environment) || "development";
};

const API_BASE_PATHS: APIBasePaths = API_CONFIG[getCurrentEnvironment()];

export default API_BASE_PATHS;
