import React, { useState, useCallback, useEffect  } from "react";
import { Button } from "@components/ui/button";
import { Input } from "@components/ui/input";
import Navbar from "@components/narbar/narbar";
import { Plus, Trash2 } from "lucide-react";
import { Progress } from "@components/ui/progress";
import { useFiles } from "./hooks/use_files";
import { useUploads } from "./hooks/use_uploads";
import FileUploadArea from "./components/FileUploadArea";
import FileList from "./components/FileList";
import CreateFolderDialog from "./components/CreateFolderDialog";
import TrashDialog from "./components/TrashDialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@components/ui/tabs";

export default function Dashboard() {
  const [showUploadArea, setShowUploadArea] = useState(false);
  const [showCreateFolderDialog, setShowCreateFolderDialog] = useState(false);
  const [showTrashDialog, setShowTrashDialog] = useState(false);
  const { files, trashFiles = [], totalStorageUsed, getfilesForUser, createFolder, moveToTrash, restoreFromTrash, emptyTrash } = useFiles();
  const { uploadFiles, uploadProgress } = useUploads();

  const storageLimit = 1024 * 1024 * 1024;
  const storageUsagePercentage = (totalStorageUsed / storageLimit) * 100;



  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    setShowUploadArea(true);
  }, []);

  const onDragLeave = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    setShowUploadArea(false);
  }, []);

  const onDrop = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    handleFiles(droppedFiles);
    setShowUploadArea(false);
  }, []);

  const handleFiles = (selectedFiles: File[]) => {
    uploadFiles(selectedFiles, getfilesForUser);
  };

  const handleCreateFolder = (folderName: string) => {
    createFolder(folderName);
    setShowCreateFolderDialog(false);
  };

  const handleMoveToTrash = (fileName: string) => {
    moveToTrash(fileName);
    getfilesForUser();
  };

  const handleRestoreFromTrash = (fileName: string) => {
    restoreFromTrash(fileName);
    getfilesForUser();
  };

  const handleEmptyTrash = () => {
    emptyTrash();
    getfilesForUser();
  };

  return (
    <div className="flex flex-col h-screen">
      <Navbar />

      <main
        className="flex-1 overflow-y-auto bg-gray-50"
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="md:hidden mb-4">
            <Input
              type="search"
              placeholder="Buscar en ZapTrack"
              className="w-full"
            />
          </div>
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-semibold text-gray-900">
              Mis archivos
            </h1>
            <div className="flex space-x-2">
              <Button
                className="bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500"
                onClick={() => setShowUploadArea(true)}
              >
                <Plus className="mr-2 h-4 w-4" /> Subir archivo
              </Button>
              <Button
                variant="outline"
                onClick={() => setShowCreateFolderDialog(true)}
              >
                <Plus className="mr-2 h-4 w-4" /> Nueva carpeta
              </Button>
              <Button
                variant="outline"
                onClick={() => setShowTrashDialog(true)}
              >
                <Trash2 className="mr-2 h-4 w-4" /> Papelera
              </Button>
            </div>
          </div>

          {/* Storage usage */}
          <div className="mb-6">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-medium text-gray-700">
                Almacenamiento usado
              </span>
              <span className="text-sm font-medium text-gray-700">
                {(totalStorageUsed / (1024 * 1024)).toFixed(2)} MB / 1 GB
              </span>
            </div>
            <Progress value={storageUsagePercentage} className="w-full" />
          </div>

          <FileUploadArea
            show={showUploadArea}
            onClose={() => setShowUploadArea(false)}
            onFileSelect={handleFiles}
            uploadProgress={uploadProgress}
          />

          <Tabs defaultValue="files" className="w-full">
            <TabsList>
              <TabsTrigger value="files">Archivos</TabsTrigger>
              <TabsTrigger value="trash">Papelera</TabsTrigger>
            </TabsList>
            <TabsContent value="files">
              <FileList files={files} onRefresh={getfilesForUser} onMoveToTrash={handleMoveToTrash} />
            </TabsContent>
            <TabsContent value="trash">
              <FileList files={trashFiles} onRefresh={getfilesForUser} onRestoreFromTrash={handleRestoreFromTrash} isTrash />
              {trashFiles.length > 0 && (
                <Button variant="destructive" onClick={handleEmptyTrash} className="mt-4">
                  Empty Trash
                </Button>
              )}
            </TabsContent>
          </Tabs>
        </div>
      </main>

      <CreateFolderDialog
        isOpen={showCreateFolderDialog}
        onClose={() => setShowCreateFolderDialog(false)}
        onCreateFolder={handleCreateFolder}
      />

      <TrashDialog
        isOpen={showTrashDialog}
        onClose={() => setShowTrashDialog(false)}
        trashFiles={trashFiles}
        onRestore={handleRestoreFromTrash}
        onEmptyTrash={handleEmptyTrash}
      />
    </div>
  );
}