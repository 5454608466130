import { useState, useEffect } from "react";
import  { JwtPayload, jwtDecode } from "jwt-decode";

interface CustomJwtPayload extends JwtPayload {
  _id: string;
  Name: string;
  email: string;
  BirthDate: string;
  DocumentNumber: string;
  Role: string;
  Position: string;
}

export const useUserIDFromToken = () => {
  const [userID, setUserID] = useState<string | null>(null);

  useEffect(() => {
    const getUserIDFromToken = () => {
      const user = localStorage.getItem("user");

      if (user) {
        try {
          const { item } = JSON.parse(user);
          const { token } = item;
          const decoded = jwtDecode<CustomJwtPayload>(token);
          if (decoded && decoded._id) {
            setUserID(decoded._id);
          }
        } catch (error) {
          console.error("Error decoding token:", error);
          setUserID(null);
        }
      }
    };

    getUserIDFromToken();
  }, []);

  return userID;
};
