/* eslint-disable @typescript-eslint/no-explicit-any */
import { login } from "@api/resources/auth";
import { useState } from "react";
import { useCallServices } from "../../hooks";

const Errors = {
  form: "Debes ingresar todos los datos",
};

export const useLogin = () => {
  const { callEndpoint, loading } = useCallServices();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const initLogin = async () => {
    try {
      if (!email || !password) throw new Error(Errors.form);

      const response = await callEndpoint(login({ email, password }));
      
      localStorage.setItem("user", JSON.stringify(response));
      return { success: true };
    } catch (err: any) {
      console.log("this is the error:", err);

      let errorMessage = "Ha ocurrido un error. Por favor, inténtalo de nuevo.";

      if (err && typeof err === "object" && "message" in err) {
        const message = err.message;

        if (message.includes("User with email")) {
          errorMessage = "El usuario con este correo electrónico no existe.";
        } else if (message.includes("Password incorrect")) {
          errorMessage = "La contraseña es incorrecta.";
        } else {
          errorMessage = message;
        }
      }

      return { success: false, error: errorMessage };
    }
  };

  const handleEmail = (text: string) => setEmail(text);
  const handlePassword = (text: string) => setPassword(text);

  return {
    email,
    password,
    handleEmail,
    handlePassword,
    initLogin,
    loading,
  };
};
