import { useErrorHandler } from "@api/handlers/handlerError";
import {
  downGetFiles,
  createDirectory,
  moveFile,
  moveToTrash,
  restoreFromTrash,
  emptyTrash,
} from "@api/resources/storage";
import { useState, useCallback, useEffect } from "react";
import { useCallServices } from "@hooks/index";
import { useUserIDFromToken } from "@hooks/useUserIDFromToken";

export const useFiles = () => {
  const { callEndpoint } = useCallServices();
  const [files, setFiles] = useState<any[]>([]);
  const [trashFiles, setTrashFiles] = useState<any[]>([]);
  const [totalStorageUsed, setTotalStorageUsed] = useState<number>(0);
  const handleError = useErrorHandler();
  const userID = useUserIDFromToken();

  useEffect(() => {
    getfilesForUser(); 
  }, [userID]);

  const getfilesForUser = useCallback(async () => {
    try {
      const response = await callEndpoint(downGetFiles(userID!));
      setFiles(
        response.files.filter((file: any) => file.directory !== "/.trash")
      );
      setTrashFiles(
        response.files.filter((file: any) => file.directory === "/.trash")
      );
      setTotalStorageUsed(response.total_storage_used);
    } catch (error) {
      handleError(error);
    }
  }, [userID, callEndpoint, handleError]);

  const createFolder = useCallback(
    async (folderName: string, parentDirectory: string = "/") => {
      try {
        await callEndpoint(
          createDirectory(userID!, folderName, parentDirectory)
        );
        getfilesForUser();
      } catch (error) {
        handleError(error);
      }
    },
    [userID, callEndpoint, handleError, getfilesForUser]
  );

  const moveFileToDirectory = useCallback(
    async (fileId: string, newDirectory: string) => {
      try {
        await callEndpoint(moveFile(userID!, fileId, newDirectory));
        getfilesForUser();
      } catch (error) {
        handleError(error);
      }
    },
    [userID, callEndpoint, handleError, getfilesForUser]
  );

  const moveToTrashFile = useCallback(
    async (fileId: string) => {
      try {
        await callEndpoint(moveToTrash(userID!, fileId));
        getfilesForUser();
      } catch (error) {
        handleError(error);
      }
    },
    [userID, callEndpoint, handleError, getfilesForUser]
  );

  const restoreFromTrashFile = useCallback(
    async (fileId: string) => {
      try {
        await callEndpoint(restoreFromTrash(userID!, fileId));
        getfilesForUser();
      } catch (error) {
        handleError(error);
      }
    },
    [userID, callEndpoint, handleError, getfilesForUser]
  );

  const emptyTrashFiles = useCallback(async () => {
    try {
      await callEndpoint(emptyTrash(userID!));
      getfilesForUser();
    } catch (error) {
      handleError(error);
    }
  }, [userID, callEndpoint, handleError, getfilesForUser]);

  return {
    files,
    trashFiles,
    totalStorageUsed,
    getfilesForUser,
    createFolder,
    moveFileToDirectory,
    moveToTrashFile,
    restoreFromTrashFile,
    emptyTrashFiles,
  };
};
