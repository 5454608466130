/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dependencies } from "..";

export const build = ({ http, abortController }: Dependencies) => {
  const PATH = "/file-directory";
  const execute = (userID: string, fileId: string, newDirectory: string) => {
    const controller = abortController();

    return {
      response: http.put<any>(`${PATH}/move-file`, {
        userID,
        fileId,
        newDirectory,
      }),
      controller,
    };
  };
  return execute;
};
