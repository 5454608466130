import  { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";
import { Button } from "@components/ui/button";
import { MoreVertical, Download, Trash, Edit, Move, Info } from "lucide-react";
import { useFiles } from "../hooks/use_files";
import RenameFileDialog from "../components/modals/RenameFileDialog";
import MoveFileDialog from "../components/modals/MoveFileDialog";
import FileDetailsDialog from "../components/modals/FileDetailsDialog";

interface FileOptionsMenuProps {
  file: any;
  onRefresh: () => void;
}

export default function FileOptionsMenu({ file, onRefresh }: FileOptionsMenuProps) {
  const { downloadFile, deleteFile, renameFile, moveFile } = useFiles();
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);

  const handleDownload = () => {
    downloadFile(file.file_name);
  };

  const handleDelete = async () => {
    if (confirm(`Are you sure you want to delete ${file.file_name}?`)) {
      await deleteFile(file.file_name);
      onRefresh();
    }
  };

  const handleRename = async (newName: string) => {
    await renameFile(file.file_name, newName);
    setShowRenameDialog(false);
    onRefresh();
  };

  const handleMove = async (newPath: string) => {
    await moveFile(file.file_name, newPath);
    setShowMoveDialog(false);
    onRefresh();
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <MoreVertical className="h-5 w-5 text-gray-400" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={handleDownload}>
            <Download className="mr-2 h-4 w-4" />
            Descargar
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleDelete}>
            <Trash className="mr-2 h-4 w-4" />
            Borrar
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setShowRenameDialog(true)}>
            <Edit className="mr-2 h-4 w-4" />
            Renombrar
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setShowMoveDialog(true)}>
            <Move className="mr-2 h-4 w-4" />
            Mover
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setShowDetailsDialog(true)}>
            <Info className="mr-2 h-4 w-4" />
            Detalles
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <RenameFileDialog
        isOpen={showRenameDialog}
        onClose={() => setShowRenameDialog(false)}
        onRename={handleRename}
        currentName={file.file_name}
      />

      <MoveFileDialog
        isOpen={showMoveDialog}
        onClose={() => setShowMoveDialog(false)}
        onMove={handleMove}
        currentPath={file.directory}
      />

      <FileDetailsDialog
        isOpen={showDetailsDialog}
        onClose={() => setShowDetailsDialog(false)}
        file={file}
      />
    </>
  );
}