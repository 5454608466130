import { Button } from "@components/ui/button";
import { Progress } from "@components/ui/progress";
import { Upload, X } from "lucide-react";

interface FileUploadAreaProps {
  show: boolean;
  onClose: () => void;
  onFileSelect: (files: File[]) => void;
  uploadProgress: Record<string, number>;
}

export default function FileUploadArea({
  show,
  onClose,
  onFileSelect,
  uploadProgress,
}: FileUploadAreaProps) {
  if (!show) return null;

  return (
    <div className="mb-6 p-4 border-2 border-dashed border-gray-300 rounded-lg relative">
      <button
        onClick={onClose}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        aria-label="Cerrar área de carga"
      >
        <X className="h-5 w-5" />
      </button>
      <div className="text-center">
        <Upload className="mx-auto h-12 w-12 text-gray-400" />
        <p className="mt-1 text-sm text-gray-600">
          Arrastra y suelta archivos aquí, o haz clic para seleccionar archivos
        </p>
        <input
          type="file"
          multiple
          onChange={(e) => onFileSelect(Array.from(e.target.files || []))}
          className="hidden"
          id="fileInput"
        />
        <Button
          onClick={() => document.getElementById("fileInput")?.click()}
          className="mt-2"
        >
          Seleccionar archivos
        </Button>
      </div>
      {Object.entries(uploadProgress).map(([fileId, progress]) => (
        <div key={fileId} className="mt-4">
          <div className="flex justify-between items-center mb-1">
            <span className="text-sm font-medium text-gray-700">{fileId}</span>
            <span className="text-sm font-medium text-gray-700">
              {progress}%
            </span>
          </div>
          <Progress value={progress} className="w-full" />
        </div>
      ))}
    </div>
  );
}