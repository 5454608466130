import { Folder, File, FileText, FileSpreadsheet, FileImage, FileAudio, FileVideo, RefreshCw } from "lucide-react";
import { Dialog, DialogContent, DialogTrigger } from "@components/ui/dialog";
import { Button } from "@components/ui/button";
import FileOptionsMenu from "./FileOptionsMenu";

interface FileListProps {
  files: any[];
  onRefresh: () => void;
  onMoveToTrash?: (fileName: string) => void;
  onRestoreFromTrash?: (fileName: string) => void;
  isTrash?: boolean;
}

const getFileIcon = (fileName: string) => {
  const extension = fileName.split('.').pop()?.toLowerCase();
  switch (extension) {
    case 'doc':
    case 'docx':
      return <File className="h-10 w-10 text-blue-500" />;
    case 'xls':
    case 'xlsx':
      return <FileSpreadsheet className="h-10 w-10 text-green-500" />;
    case 'ppt':
    case 'pptx':
      return <File className="h-10 w-10 text-orange-500" />;
    case 'pdf':
      return <File className="h-10 w-10 text-red-500" />;
    case 'txt':
      return <FileText className="h-10 w-10 text-gray-500" />;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
      return <FileImage className="h-10 w-10 text-purple-500" />;
    case 'mp3':
    case 'wav':
    case 'ogg':
      return <FileAudio className="h-10 w-10 text-yellow-500" />;
    case 'mp4':
    case 'avi':
    case 'mov':
      return <FileVideo className="h-10 w-10 text-pink-500" />;
    default:
      return <File className="h-10 w-10 text-gray-500" />;
  }
};

export default function FileList({ files, onRefresh, onMoveToTrash, onRestoreFromTrash, isTrash = false }: FileListProps) {
  const isImage = (fileName: string) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const extension = fileName.split(".").pop()?.toLowerCase() || "";
    return imageExtensions.includes(extension);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-6">
      {files.map((file) => (
        <div
          key={file.file_name}
          className="bg-white rounded-lg shadow-sm p-4 flex items-center space-x-4"
        >
          {file.directory === "/" ? (
            isImage(file.file_name) ? (
              <Dialog>
                <DialogTrigger asChild>
                  <img
                    src={file.service_url}
                    alt={file.file_name}
                    className="h-10 w-10 object-cover rounded cursor-pointer"
                  />
                </DialogTrigger>
                <DialogContent className="sm:max-w-lg">
                  <img
                    src={file.service_url}
                    alt={file.file_name}
                    className="w-full h-auto"
                  />
                </DialogContent>
              </Dialog>
            ) : (
              getFileIcon(file.file_name)
            )
          ) : (
            <Folder className="h-10 w-10 text-blue-500" />
          )}
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 truncate">
              {file.file_name}
            </p>
            <p className="text-xs text-gray-500">
              {isTrash
                ? `Deleted: ${new Date(file.deleted_at).toLocaleString()}`
                : `Size: ${(file.file_size / 1024).toFixed(2)} KB`}
            </p>
          </div>
          {isTrash ? (
            <Button
              variant="outline"
              size="sm"
              onClick={() => onRestoreFromTrash && onRestoreFromTrash(file.file_name)}
              className="flex items-center space-x-1"
            >
              <RefreshCw className="h-4 w-4" />
              <span>Restore</span>
            </Button>
          ) : (
            <FileOptionsMenu file={file} onRefresh={onRefresh} onMoveToTrash={onMoveToTrash} />
          )}
        </div>
      ))}
    </div>
  );
}