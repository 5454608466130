/* eslint-disable @typescript-eslint/no-explicit-any */
import { useToast } from "@hooks/use-toast";

export const useErrorHandler = () => {
  const { toast } = useToast();

  return (error: any) => {
    if (error.message === "La solicitud fue cancelada") {
      return;
    }

    const title = error.title || "Error";
    const description = error.message;

    toast({
      variant: "destructive",
      title,
      description,
    });
  };
};
